<template>
  <nav class="navbar navbar-light" style="background-color: #E13122;">
    <span class="navbar-brand mb-0 h1" style="margin-left:10%;color:#ffffff;font-size:2rem">Matchday</span>
  </nav>
  <div id="privacy" style="background-color:#f4f4f4 !important">
    <br />
    <br />
    <div class="row">
      <div class="container mb-5" style="background-color:white !important;border-radius:25px">
        <div class="row no-gutters">
          <div class="col-12 ml-2" style="text-align:center">
            <div class="text-center">
              <img id="" class=""
                src="https://prod-media-as1.s3.ap-southeast-1.amazonaws.com/miscellaneous/Matchday_Logo-01_jlclfy.jpg"
                style="height: 200px; width: 200px; border-radius: 25%" />
              <br />
            </div>
          </div>
          <div class="col-12">
            <span style="font-size: 24px" class="ml-n3">
              &nbsp; &nbsp;<b> เงื่อนไขการให้บริการแพลตฟอร์มระหว่าง Matchday และสนามกีฬา</b></span>
          </div>
          <div class="col-12 mx-3">
            <div class="divider mb-3 mt-3"></div>
          </div>

          <div class="col-12">
            <div class="detail">
              <p class="c" style="margin-left: 20px; margin-right: 20px; font-size: 14px">
                ปรับปรุงครั้งล่าสุดวันที่ 7 ตุลาคม 2567<br />
              </p>
              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <br />
                <span style="font-size: 16px">
                  <b> ข้อ 1 คำนิยามและการตีความ</b> <br />

                  <b>แพลตฟอร์ม</b> หมายถึง แอปพลิเคชั่นและเว็ปไซต์ และ/หรือ เว็ปแอปพลิเคชั่น ที่บริษัท แมทช์เดย์ ฮับ
                  จำกัด (“บริษัทฯ”) เป็นเจ้าของ และให้บริการแก่สนามกีฬาโดยไม่จำกัดแต่เพียงผู้เดียว (Non-exclusive)
                  ซึ่งรวมถึง ระบบบริหารจัดการสนามกีฬา ARENA
                  <br /><b>สนามกีฬา</b> หมายถึง ธุรกิจ ผู้ประกอบการ เจ้าของ ผู้บริหาร
                  ผู้ให้บริการเช่าพื้นที่เพื่อเล่นกีฬารวมถึงแต่ไม่จำกัดเพียงกีฬาใดกีฬาหนึ่ <br /><b>ผู้ใช้งาน</b>
                  หมายถึง พนักงาน ผู้จัดการ เจ้าหน้าที่ หรือ ผู้ได้รับมอบอำนาจการบริหารจัดการของสนามกีฬา
                  <br /><b>ตารางการจองสนาม</b> หมายถึง ช่วงเวลาการให้บริการเช่าพื้นที่เพื่อเล่นกีฬารวมถึง
                  แต่ไม่จำกัดเพียงกีฬาใดกีฬาหนึ่งของสนามกีฬา ซึ่งสนามกีฬาตกลงจ้างบริษัทฯ เพื่อบริหาร ดูแล ควบคุม
                  รวมถึงการเพิ่ม แก้ไข ปรับเปลี่ยนบนแพลตฟอร์ม รวมถึงสามารถทำการจอง ยืนยัน รวมถึงยกเลิกได้ในแพลตฟอร์ม
                  <br /><b>รายได้</b> หมายถึง รายรับที่สนามกีฬาได้รับจากลูกค้าจากการให้บริการจากธุรกิจของสนามกีฬาเอง
                  <br /><b>เนื้อหา</b> หมายถึง ข้อความ ภาพเขียน ภาพวาด กราฟฟิค ภาพถ่าย ภาพเคลื่อนไหว เสียง
                  ข้อมูลและ/หรือสารสนเทศอื่นในรูปแบบของ ประกาศ โปสเตอร์ แผ่นป้าย ป้ายประกาศ เครื่องหมาย สติกเกอร์
                  หรือรูปแบบสื่อเดียวหรือหลายสื่อรวมกัน ซึ่งอาจอยู่ในรูปแบบออฟไลน์ และ/หรือออนไลน์
                  ที่เป็นการโฆษณาเกี่ยวกับ สนามกีฬา บริษัทฯ และ/หรือบริการของบริษัทฯ รวมถึงลูกค้าของสนามกีฬา ที่บริษัทฯ
                  มีสิทธิที่จะแสดง
                  <br /><b>ภาษีมูลค่าเพิ่ม</b> หมายถึง ภาษีมูลค่าเพิ่มตามที่กำหนดไว้ในประมวลรัษฎากรไทย <br />
                  <b>ลูกค้า</b> หมายถึง ลูกค้าของสนามกีฬา (ไม่จำกัดสถานะของบุคคลใดบุคคลหนึ่ง)
                  ที่เข้าใช้บริการเช่าพื้นที่เพื่อเล่นกีฬารวมถึงแต่ไม่จำกัดเพียงกีฬาใดกีฬาหนึ่ง
                  (ซึ่งทุกการจองเข้าใช้พื้นที่เพื่อเล่นกีฬาให้เรียกว่า “สัญญา”)
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b> ข้อ 2 เงื่อนไขการให้บริการ</b><br />
                  2.1 สนามกีฬาตกลงรับสิทธิเข้าใช้งานแพลตฟอร์มดังกล่าว โดยสนามกีฬาตกลงและยอมรับที่จะใช้งานแพลตฟอร์ม ARENA
                  ตามข้อตกลงฉบับนี้<br>
                  2.2 แพลตฟอร์มทำหน้าที่เป็นเพียงสื่อกลางระหว่างสนามกีฬาและลูกค้า โดยที่บริษัทฯ
                  จะไม่รับผิดชอบต่อความเสียหายใดๆ ที่เกิดขึ้น
                </span>
              </p>

             

             

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b>ข้อ 3 ระยะเวลาของการใช้งาน</b><br />
                  สนามกีฬาสามารถเข้าใช้งานแพลตฟอร์มได้ตามกำหนดระยะเวลาที่กำหนดตามข้อตกลง
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px"></p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b> ข้อ 4 บัญชีการใช้งาน </b><br />
                  4.1 การลงทะเบียน ผู้ใช้งานจะต้องสร้างรหัสผ่าน (password) สำหรับผ่านเข้าใช้บัญชีของผู้ใช้งานๆ
                  จะต้องเก็บรักษารหัสผ่านเป็นความลับ และขอให้เปลี่ยนรหัสผ่านดังกล่าวเป็นประจำ
                  ผู้ใช้งานตกลงว่าบุคคลอื่นที่เข้าใช้บริการ (และ/หรือ ทำธุรกรรม) หรือ เว็บไซต์
                  โดยใช้ชื่อบัญชีและรหัสผ่านที่ผู้ใช้งานเป็นผู้เปิดเผยให้ทราบถือเป็นตัวแทนของผู้ใช้งาน ทั้งนี้
                  ผู้ใช้งานจะเป็นผู้รับผิดชอบต่อความเสียหายที่เกิดขึ้นจากการไม่รักษารหัสผ่านของผู้ใช้งานเป็นความลับ
                </span>
              </p>

             

             

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b>ข้อ 5 ค่าธรรมเนียม</b><br />
                  5.1 ผู้ใช้ตกลงจะชำระค่าธรรมเนียมการใช้บริการซอฟต์แวร์ของ matchday ให้แก่ matchday ตามที่ matchday
                  กำหนด โดยชำระผ่านช่องทางเรากำหนด<br>
                  5.2 ค่าธรรมเนียมจะไม่รวมถึง GST/VAT และภาษีที่เกี่ยวข้องอื่นๆ เว้นแต่ตกลงกันไว้เป็นอย่างอื่น
                  ผู้ใช้จะรับผิดชอบค่าภาษีเหล่านั้นเอง ผู้ใช้รับทราบและตกลงว่า matchday
                  สามารถหักค่าธรรมเนียมและภาษีที่เกี่ยวข้อง และ matchday
                  จะออกใบเสร็จรับเงินหรือใบกำกับภาษีสำหรับค่าธรรมเนียมและภาษีที่ผู้ขายชำระไว้เมื่อร้องขอ<br>
                  5.3 ภายหลังจากผู้ใช้ได้ชำระค่าธรรมเนียมการใช้บริการซอฟต์แวร์ให้แก่ matchday แล้ว matchday
                  ขอสงวนสิทธิ์ไม่คืนเงินค่าธรรมเนียมดังกล่าวทุกกรณี
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px"></p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b>ข้อ 6 สิทธิและหน้าที่ของบริษัทฯ</b><br />
                  บริษัทฯ ตกลงและจะปฏิบัติหน้าที่ดังต่อไปนี้<br>
                  6.1 บริษัทฯ เป็นผู้รับผิดชอบงานในส่วนการปรับปรุง ทดสอบ และพัฒนาแพลตฟอร์ม
                  ซึ่งรวมถึงการปรับเปลี่ยนเนื้อหาที่เป็นลิขสิทธิ์ของสนามกีฬาและอยู่บนแพลตฟอร์มของ ทั้งสิ้น
                  ด้วยค่าใช้จ่ายของตนเองทั้งสิ้น<br>
                  6.2 บริษัทฯ รับรองว่า บริษัทฯ มีสิทธิในการบริหารจัดการแพลตฟอร์มโดยถูกต้อง สมบูรณ์
                  และชอบด้วยกฎหมายของการให้บริการแพลตฟอร์ม หรือการทำธุรกรรมใดๆ
                  ที่เกี่ยวข้องกับแพลตฟอร์มนี้จะไม่ก่อให้เกิดการขัดแย้งตามกฎหมาย หรือ การผิดสัญญาอื่นใด<br>
                  6.3 บริษัทฯ จะเผยแพร่เนื้อหาเฉพาะข้อมูลที่จำเป็นบนแพลตฟอร์มให้สนามกีฬาผ่านบัญชีผู้ใช้ที่สนามกีฬากำหนด
                  เพื่อให้สนามกีฬาสามารถเข้าถึงข้อมูลการจัดการสนาม และลูกค้าของสนามกีฬาได้อย่างต่อเนื่อง
                  ถูกต้องและครบถ้วน ปราศจากการรบกวน ซึ่งรวมถึงการไม่ขัดต่อเงื่อนไขและข้อกำหนด
                  และนโยบายความเป็นส่วนตัวที่บริษัทฯ ได้รับทราบและทำความเข้าใจอย่างละเอียด<br>
                  6.4 บริษัทฯ
                  จะเผยแพร่เนื้อหาโฆษณาให้แก่สนามกีฬาบนแพลตฟอร์มเพื่อให้ลูกค้าสามารถใช้สื่อโฆษณาได้อย่างต่อเนื่อง
                  ถูกต้อง ครบถ้วน ปราศจากการรบกวนซึ่งค่าใช้จ่ายการเผยแพร่เนื้อหาโฆษณาบนแพลตฟอร์มทั้งหมด<br>
                  6.5 บริษัทฯ มีหน้าที่ต้องดูแล ซ่อมแซม บำรุงรักษาแพลตฟอร์ม ให้อยู่ในสภาพเรียบร้อย
                  สะอาดและสวยงามและใช้ได้เป็นประจำและต่อเนื่องอยู่เสมอ หากแพลตฟอร์มเกิดชำรุดเสียหายหรือทรุดโทรม บริษัทฯ
                  ต้องรีบดำเนินการซ่อมแซม แก้ไข ปรับปรุง ให้กลับคืนดีดังเดิมให้แล้วเสร็จภายใน ยี่สิบสี่ชั่วโมง
                  โดยบริษัทฯ จะเป็นผู้ออกค่าใช้จ่ายเองทั้งสิ้น<br>
                  6.6 บริษัทฯ ไม่อาจให้การรับรองหรือรับประกันได้ว่า
                  สนามกีฬาจะสามารถเข้าใช้แพลตฟอร์มได้อย่างต่อเนื่องโดยไม่มีการหยุดชะงักใดๆ หรือไม่มีข้อบกพร่องใดๆ
                  และบริษัทฯ จะไม่รับผิดชอบต่อการกระทำโดยจงใจ หรือประมาทเลินเล่อในการทำหน้าที่ของบุคคลที่สาม
                  รวมถึงแต่ไม่จำกัดเพียงปัญหาการเชื่อมต่ออินเทอร์เน็ต การติดขัดในช่องสัญญาณ เครือข่ายอินเทอร์เน็ต
                  ภัยธรรมชาติ อัคคีภัย และจากปัญหาที่ไม่เกิดจากบริษัทฯ<br>
                  6.7 บริษัทฯ จะไม่ทำการปรับเปลี่ยนหรือดัดแปลงใดๆ ในแพลตฟอร์มในส่วนเฉพาะของสนามกีฬา
                  หากไม่ได้รับความยินยอมจากบริษัทฯ เว้นแต่ (ก)
                  กรณีมีความจำเป็นเร่งด่วนเพื่อความปลอดภัยหรือเนื่องจากกรณีฉุกเฉิน
                  ซึ่งการดังกล่าวจะต้องทำไปโดยจำเป็นและตามสมควร และต้องแจ้งให้บริษัทฯ ทราบโดยทันที หรือ (ข) กรณีบริษัทฯ
                  ประสงค์รื้อถอน เปลี่ยนแปลงหรือพัฒนารูปแบบแพลตฟอร์ม โดยสนามกีฬาจะแจ้งให้บริษัทฯ ทราบล่วงหน้าอย่างน้อย
                  สามสิบ วันก่อนการรื้อถอน เปลี่ยนแปลงหรือพัฒนา
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px"></p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b>ข้อ 7 สิทธิและหน้าที่ของสนามกีฬา</b><br />
                  สนามกีฬาตกลงและจะปฏิบัติหน้าที่ดังต่อไปนี้<br>
                  7.1 สนามกีฬารับรองว่าการทำธุรกรรมใดๆ ที่เกี่ยวข้องกับการเข้าใช้งานแพลตฟอร์มนี้
                  จะไม่ก่อให้เกิดการขัดแย้งกับกฎหมาย หรือการผิดสัญญาอื่นใด<br>
                  7.2 สนามกีฬาจะต้องทำความเข้าใจและรับทราบข้อกำหนดและเงื่อนไขให้การบริการ และ
                  นโยบายความเป็นส่วนตัวของแพลตฟอร์มนี้ก่อนเข้าใช้งาน<br>
                  7.3 บริษัท/ร้านค้า ตกลงควบคุมดูแลการใช้และเปิดเผยข้อมูลส่วนบุคคล
                  ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 รวมถึงกฎหมายกฎระเบียบ ข้อบังคับ
                  คำสั่งประกาศและเงื่อนไขต่างๆของหน่วยงานราชการหรือหน่วยงานอื่นใดที่เกี่ยวข้องตลอดจน
                  มาตรการเกี่ยวกับการคุ้มครองผู้บริโภค ซึ่งใช้บังคับอยู่แล้วในขณะนี้
                  และที่จะได้ออกใช้บังคับต่อไปในภายหน้า และตกลงปฏิบัติตามข้อตกลงในการประมวลผลข้อมลูส่วนบุคคล<br>
                  7.4 สนามกีฬาตกลงที่จะไม่:<br>
                  &nbsp;&nbsp;&nbsp;(1) อัปโหลด โพสต์ ส่งต่อ หรือทำให้เนื้อหาที่ผิดกฎหมาย เป็นภัย คุกคาม ขู่เข็ญ
                  ล่วงละเมิด ทำให้ตื่นตกใจ ทำให้เป็นทุกข์ หลอกลวง ทำให้เสื่อมเสีย หยาบคาย ลามก หมิ่นประมาท
                  ล่วงล้ำความเป็นส่วนตัวของผู้อื่น น่ารังเกียจ เหยียดเชื้อชาติหรือชาติพันธุ์ ใช้งานได้<br>
                  &nbsp;&nbsp;&nbsp;(2) ใช้บริการเพื่อปลอมแปลงเป็นบุคคลหรือนิติบุคคลอื่น
                  หรือแสดงข้อมูลเท็จเกี่ยวกับความเชื่อมโยงกับบุคคลหรือนิติบุคคลใด<br>
                  &nbsp;&nbsp;&nbsp;(3)
                  ปลอมแปลงหรือปรับเปลี่ยนรหัสประจำตัวเพื่อปิดบังแหล่งกำหนดของเนื้อหาที่ส่งต่อผ่านบริการ<br>
                  &nbsp;&nbsp;&nbsp;(4) ลบประกาศเกี่ยวกับกรรมสิทธิ์ออกจากแพลตฟอร์ม<br>
                  &nbsp;&nbsp;&nbsp;(5) เป็นต้นเหตุ อนุญาตหรือมอบอำนาจให้ทำการดัดแปลง สร้างงานอนุพันธ์
                  หรือแปลบริการโดยไม่ได้รับอนุญาตอย่างชัดแจ้งจากบริษัทฯ<br>
                  &nbsp;&nbsp;&nbsp;(6)
                  ใช้บริการเพื่อประโยชน์ของบุคคลภายนอกหรือในลักษณะที่ไม่ได้รับอนุญาตตามสิทธิการใช้งานที่ให้ไว้ในที่นี้<br>
                  &nbsp;&nbsp;&nbsp;(7) ใช้บริการเพื่อจุดประสงค์ในการฉ้อฉล<br>
                  &nbsp;&nbsp;&nbsp;(8) ปรับเปลี่ยนราคาของสินค้า/การบริการ
                  หรือแทรกแซงบัญชีรายชื่อสินค้า/การบริการของผู้ใช้รายอื่น<br>
                  &nbsp;&nbsp;&nbsp;(9) ดำเนินการใดๆ ที่อาจบ่อนทำลายระบบให้ข้อคิดเห็นหรือระบบการให้คะแนน<br>
                  &nbsp;&nbsp;&nbsp;(10) พยายามแยกส่วนแฟ้ม ทำวิศวกรรมย้อนกลับ ถอดประกอบหรือเจาะระบบบริการ
                  (หรือส่วนหนึ่งส่วนใดของบริการ) หรือเอาชนะหรือทำลายเทคโนโลยีการเข้ารหัสหรือมาตรการความปลอดภัยที่บริษัทฯ
                  นำมาใช้กับบริการและ/หรือข้อมูลที่ส่งต่อ ดำเนินการ หรือจัดเก็บไว้โดยบริษัทฯ<br>
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b> ข้อ 8 การนำข้อมูลที่มีอยู่ปัจจุบันของสนามกีฬาขึ้นสู่แพลตฟอร์ม</b><br />
                  8.1 บริษัทให้ความสำคัญแก่ความต่อเนื่องของการให้บริการของสนามกีฬาเอง
                  เราจึงจัดให้มีการบริการนำข้อมูลพื้นฐานการบริการของสนามกีฬาขึ้นสู่แพลตฟอร์ม <br>
                  8.2 เพื่อประโยชน์สูงสุดของคุณ สนามกีฬาอาจให้ข้อมูลส่วนบุคคลที่เกี่ยวข้องกับบุคคลอื่น
                  แต่จำกัดวัตถุประสงค์เพื่อการใช้บริการขั้นพื้นฐานของแพลตฟอร์มเพื่อประโยชน์สูงสุดของสนามกีฬาเอง เช่น<br>
                  &nbsp;&nbsp;&nbsp;(1) ข้อความ ภาพวาด กราฟฟิค ภาพถ่าย ข้อมูล
                  เครื่องหมายที่จำเป็นต่อการบริการขั้นพื้นฐาน<br>
                  &nbsp;&nbsp;&nbsp;(2) ชื่อ นามสกุล
                  เบอร์โทรศัพท์ของคุณที่แสดงความจำนงค์จองวันและเวลาเพื่อใช้บริการสนามกีฬาหนึ่งเป็นระยะเวลาต่อเนื่อง
                  (Long-term booking) หากสนามกีฬาให้ข้อมูลส่วนนี้แก่เรา
                  คุณรับรองและรับประกันว่าคุณได้รับความยินยอมให้มีการเก็บรวบรวม ใช้
                  และเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้คุณอื่นแล้ว<br>
                  8.3 สนามกีฬาตกลงควบคุมดูแลการใช้และเปิดเผยข้อมูลส่วนบุคคลตามข้อ 7.3
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px"></p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b>ข้อ 9 การจัดทำข้อมูลแผงควบคุมเชิงวิเคราะห์ (Analytics Dashboard)</b><br />
                  9.1 สำหรับสนามกีฬาที่ได้รับสิทธิโดยไม่จำกัดแต่เพียงผู้เดียวให้เข้าถึงการบริการแพลตฟอร์ม
                  จะได้สิทธิเข้าถึงการบริการใช้งานแผงควบคุมเชิงวิเคราะห์ (Analytics Dashboard)
                  ที่แสดงถึงกลุ่มผู้ใช้งานเป้าหมายเชิงประชากรศาสตร์และการตลาดดิจิทัลของสนามกีฬาตนเอง<br>
                  9.2 แพลตฟอร์มจะเก็บ รวบรวม ใช้ และเปิดเผยอย่างที่ไม่สามารถระบุตัวตนข้อมูลเชิงประชากรศาสตร์
                  (เพศและอายุ) ของผู้ใช้งาน เพื่อใช้ในการบริการดังกล่าวแก่สนามกีฬานั้นๆ
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px"></p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b>ข้อ 10 การสิ้นสุดการให้บริการ</b><br />
                  10.1 เมื่อฝ่ายใดฝ่ายหนึ่งผิดข้อกำหนดและเงื่อนไขข้อใดข้อหนึ่งตามที่ระบุไว้ในข้อตกลงฉบับนี้
                  และข้อกำหนดและเงื่อนไขที่ข้อตกลงฉบับนี้อ้างถึง และมิได้แก้ไขเหตุแห่งการผิดข้อตกลง<br>
                  10.2 เมื่อคู่สัญญาฝ่ายใดฝ่ายหนึ่งเข้าสู่กระบวนการชำระบัญชี หรือเลิกบริษัท หรือเลิกกิจการ
                  หรือในกรณีที่มีการอายัดหรือยึดทรัพย์สินของคู่สัญญาฝ่ายนั้น
                  คู่สัญญาอีกฝ่ายหนึ่งมีสิทธิบอกเลิกการให้บริการได้
                  โดยให้มีผลทันทีนับจากวันที่ระบุในหนังสือบอกเลิกการให้บริการ<br>
                  10.3 เมื่อคู่สัญญาฝ่ายใดฝ่ายหนึ่งตกเป็นบุคคลล้มละลาย
                  หรือเจ้าพนักงานพิทักษ์ทรัพย์ได้รับมอบหมายให้เข้าดำเนินกิจการของคู่สัญญาฝ่ายนั้น
                  หรือคู่สัญญาฝ่ายนั้นเข้าสู่กระบวนการฟื้นฟูกิจการ ให้ข้อตกลงฉบับนี้สิ้นสุดลงโดยอัตโนมัติทันที หนึ่ง
                  วันก่อนวันที่ศาลมีคำสั่งพิทักษ์ทรัพย์หรือวันที่มีวันยื่นขอฟื้นฟูกิจการนั้น (แล้วแต่กรณี)
                  โดยไม่ต้องมีหนังสือแจ้งไปยังคู่สัญญาอีกฝ่ายหนึ่ง<br>
                  10.4 การเลิกหรือสิ้นสุดข้อตกลงฉบับนี้
                  จะไม่กระทบความรับผิดหรือสิทธิในการเรียกค่าเสียหายของคู่สัญญาฝ่ายหนึ่งฝ่ายใดที่มีหรือเกิดขึ้นก่อนข้อตกลงฉบับนี้จะถูกบอกเลิกหรือสิ้นสุดลง
                  รวมทั้งไม่กระทบหน้าที่ใดๆ ที่สัญญานี้ระบุไว้ชัดว่ายังคงมีอยู่
                  แม้ว่าข้อตกลงฉบับนี้จะถูกบอกเลิกหรือสิ้นสุดลงก็ตาม ทั้งนี้ค่าเสียหายที่บริษัทฯ จะได้รับจากสนามกีฬา
                  ให้รวมถึงค่าปรับที่บริษัทฯ จะต้องชำระให้กับลูกค้าของสนามกีฬา
                  จากการไม่ปฏิบัติตามสัญญาเนื่องจากความผิดของสนามกีฬาด้วย<br>
                  10.5 ในกรณีที่สนามกีฬาประสงค์จะเลิกหรือสิ้นสุดการให้บริการ สนามกีฬาสามารถส่งคำขอมาที่
                  admin@matchday.co.th การยื่นคำขอดังกล่าวจะต้องทำเป็นเวลาอย่างน้อย สามสิบ (30)
                  วันก่อนถึงกำหนดชำระค่าบริการ โดยมีผลเป็นการเลิกการให้บริการในวันครบกำหนดชำระค่าบริการ
                  ในกรณีที่สนามกีฬาประสงค์จะเลิกการให้บริการโดยให้มีผลทันที (ยุติการให้บริการก่อนครบกำหนดชำระค่าบริการ)
                  บริษัทฯ จะไม่คืนเงินค่าบริการที่บริษัทฯ ชำระไว้แล้วไม่ว่ากรณีใดๆ
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px"></p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b>ข้อ 11 เบ็ดเตล็ด</b><br />
                  11.1 ข้อตกลงฉบับนี้ถือเป็นข้อตกลงระหว่างคู่สัญญาเกี่ยวกับเนื้อหาตามที่กำหนดไว้ และให้มีผลยกเลิกข้อตกลง
                  ความเข้าใจ หรือการติดต่อใดๆ ก่อนหน้านั้นไม่ว่าด้วยวาจาหรือทำเป็นหนังสือระหว่างคู่สัญญา<br />
                  11.2 ข้อตกลงฉบับนี้อาจมีการปรับปรุง แก้ไข ตัดทอน ยกเลิก เปลี่ยนแปลงหรือเพิ่มเติม
                  ไม่ว่าทั้งหมดหรือบางส่วนได้ โดยมีเงื่อนไขว่าคู่สัญญาทั้งสองฝ่ายต้องยินยอม
                  และทำเป็นหนังสือและลงนามโดยผู้มีอำนาจของคู่สัญญาทั้งสองฝ่าย<br />
                  11.3 คู่สัญญาทั้งสองฝ่ายไม่อาจที่จะให้สิทธิ โอนสิทธิ หน้าที่ ภาระผูกพัน หรือผลประโยชน์
                  ทั้งหลายหรือบางส่วนที่กำหนดไว้ในข้อตกลงฉบับนี้ หรือที่มีอยู่ตามสัญญาฉบับนี้แก่บุคคลที่สาม
                  โดยปราศจากความยินยอมเป็นหนังสือจากคู่สัญญาอีกฝ่ายหนึ่ง<br />
                  11.4 หากในเวลาใดๆ ข้อความที่กำหนดไว้ในข้อตกลงฉบับนี้ ไม่ว่าจะทั้งหมดหรือบางส่วน เป็นหรือมีอันเป็นโมฆะ
                  ผิดกฎหมาย หรือไม่สามารถบังคับใช้ได้ ย่อมไม่มีผลกระทบต่อความสมบูรณ์
                  ความถูกต้องตามกฎหมายหรือการบังคับใช้ของส่วนที่เหลืออยู่ของข้อตกลงฉบับนี้
                  และคู่สัญญาทั้งสองฝ่ายตกลงที่จะทดแทนส่วนที่เป็นโมฆะ ผิดกฎหมาย
                  หรือไม่สามารถบังคับใช้ได้ตามข้อตกลงฉบับนี้ด้วยส่วนที่มีความสมบูรณ์ ถูกต้องตามกฎหมาย
                  และสามารถบังคับใช้ได้ โดยให้มีผลตราบเท่าที่ไม่ขัดต่อเป้าหมายธุรกิจหรือจุดมุ่งหมายอื่นๆ
                  ของส่วนที่เป็นโมฆะ ผิดกฎหมาย หรือไม่สามารถบังคับใช้ได้นั้น<br />
                  11.5
                  การไม่บังคับหรือร้องขอให้คู่สัญญาอีกฝ่ายหนึ่งปฏิบัติตามหน้าที่ตามข้อกำหนดและเงื่อนไขตามนโยบายความเป็นส่วนตัวภายใต้ข้อตกลงฉบับนี้
                  หรือการไม่ใช้สิทธิใดๆ ของคู่สัญญาฝ่ายหนึ่งนั้น
                  ย่อมไม่ถือว่าคู่สัญญาดังกล่าวเสียสิทธิหรือสละสิทธิในการร้องขอให้คู่สัญญาอีกฝ่ายหนึ่งทำการแก้ไข
                  หรือบรรเทาความเสียหายดังกล่าว
                  ยกเว้นการสละสิทธิได้มีการทำเป็นลายลักษณ์อักษรและลงนามโดยคู่สัญญาฝ่ายที่ไม่ใช้สิทธินั้น<br />
                  11.6 ข้อกำหนดและเงื่อนไขตามสัญญาฉบับนี้ตกอยู่ภายใต้บังคับของกฎหมายไทย
                  โดยไม่คำนึงถึงกฎหรือบทบัญญัติว่าด้วยการเลือกกฎหมายหรือการขัดกันของกฎหมาย
                  และให้ศาลในราชอาณาจักรไทยเป็นศาลที่มีเขตอำนาจในการพิจารณาชี้ขาดตัดสินข้อพิพาทที่เกิดขึ้นภายใต้ข้อกำหนดและเงื่อนไขตามสัญญาฉบับนี้<br />
                  11.7 การละเมิดข้อตกลงการให้บริการนี้อาจส่งผลให้มีการดำเนินการหลายประการ รวมถึงแต่ไม่จำกัดเพียง
                  สิ่งใดสิ่งหนึ่งหรือทั้งหมดต่อไปนี้<br>
                  &nbsp;&nbsp;&nbsp;(1) บัญชีรายชื่อถูกลบ<br>
                  &nbsp;&nbsp;&nbsp;(2) ถูกกำหนดขีดจำกัดสำหรับสิทธิพิเศษของบัญชี<br>
                  &nbsp;&nbsp;&nbsp;(3) บัญชีถูกระงับและยกเลิกในเวลาต่อมา<br>
                  &nbsp;&nbsp;&nbsp;(4) ถูกดำเนินคดีอาญา<br>
                  &nbsp;&nbsp;&nbsp;(5) ถูกดำเนินคดีทางแพ่ง
                  รวมถึงแต่ไม่จำกัดเพียงการเรียกร้องค่าเสียหายและ/หรือวิธีการชั่วคราว ก่อนพิพากษาหรือคำสั่งห้ามของศาล
                  หากคุณเชื่อว่าผู้ใช้บนแพลตฟอร์มของเราละเมิดเงื่อนไขการให้บริการนี้ โปรดติดต่อเราที่
                  admin@matchday.co.th
                  <br>
                </span>
              </p>

             


              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.getElementById("privacy").scrollIntoView();
  }
};
</script>

<style>
.divider {
  height: 1px;
  width: 95%;
  border-bottom: 1px #707070 solid;
}
</style>